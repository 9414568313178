/*
      old (iOS) icon indexes:
      
      standart = 0
      bar = 1
      ceremoney = 2
      dress = 3
      present = 4
      reception = 5
      camera = 6
      bag = 7
      airBalloons = 8
      postalCard = 9
      lock = 10
      music = 11
      rings = 12
      calendar = 13
      groom = 14
      bride = 15
      bowtie = 16

    */

const icons = [
  "/assets/icon/budget/unknown.svg", // 0
  "/assets/icon/budget/reception.svg", // 1
  "/assets/icon/budget/ceremony.svg", // 2
  "/assets/icon/budget/bridesmaid.svg", // 3
  "/assets/icon/budget/gift.svg", // 4
  "/assets/icon/budget/misc.svg", // 5
  "/assets/icon/budget/camera.svg", // 6
  "/assets/icon/budget/beauty.svg", // 7
  "/assets/icon/budget/drinks.svg", // 8
  "/assets/icon/budget/invitation.svg", // 9
  "/assets/icon/budget/honeymoon.svg", // 10
  "/assets/icon/budget/music.svg", // 11
  "/assets/icon/budget/ring.svg", // 12
  "/assets/icon/budget/notepad.svg", // 13
  "/assets/icon/budget/groom.svg", // 14
  "/assets/icon/budget/bride.svg", // 15
  "/assets/icon/budget/groomsmen.svg", // 16
]

export default icons;      