import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_m_item = _resolveComponent("m-item")!

  return (_openBlock(), _createBlock(_component_m_item, {
    label: _ctx.label,
    "error-message": _ctx.errorMessage
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_select, {
        modelValue: _ctx.modelValue,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.modelValue = $event))
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["label", "error-message"]))
}