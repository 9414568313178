
import { defineComponent } from '@vue/runtime-core'
import {
 IonSelect,
} from '@ionic/vue'
import { useField } from 'vee-validate';
import MItem from './MItem.vue';

export default defineComponent({
  props:{
    label: {type: String, required: true},
    name: {type: String, required:true},
    //errorMessage: {type: String, default: ""},
  },
  components:{
    MItem,
    IonSelect,
  },
  methods:{
    emitValue(evt: Event){
      if (evt.target != null){
        const target = evt.target as HTMLSelectElement;
        this.$emit('update:modelValue', target.value);
      }
    }
  },
  setup(props) {

    const { errorMessage, value: modelValue} = useField (props.name);

    return {
      errorMessage,
      modelValue
    };  

  },
})
