
import { defineComponent } from '@vue/runtime-core'
import {
 IonDatetime,
} from '@ionic/vue'
import { useField } from 'vee-validate';
import MItem from './MItem.vue';

export default defineComponent({
  // ** HINT:
  // use display-format="DD MMMM YYYY" for a date picker or 
  // display-format="h:mm A" for a time picker.
  // picker-format will default to display-format if it is undefined.
  props:{
    label: {type: String, required: true},
    name: {type: String, required: true},
    placeholder: {type: String, default: undefined},
    displayFormat: {type: String, default: "h:mm A"},
    displayTimezone: {type: String, default: undefined},
    pickerFormat: {type: String, default: undefined},
    min: {type: [String, Number], default: undefined},
    max: {type: [String, Number], default: undefined},
  },
  components:{
    MItem,
    IonDatetime,
  },
  setup(props) {

    const { errorMessage, value: modelValue /*, handleChange*/ } = useField (props.name);

   return {
     errorMessage,
     modelValue,
     //handleChange,
   };  

  },
})
