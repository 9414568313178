import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_m_datetime = _resolveComponent("m-datetime")!
  const _component_m_textarea = _resolveComponent("m-textarea")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_m_select = _resolveComponent("m-select")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    "default-href": "/tabs/home/bigday",
                    text: _ctx.$t('Cancel')
                  }, null, 8, ["text"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "primary" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _withModifiers(_ctx.onSubmitForm, ["prevent"])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "end",
                        icon: _ctx.checkmark
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('Save')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.itemId ? _ctx.$t('Edit Big Day Item') : _ctx.$t('New Big Day Item')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode("form", {
            action: "#",
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onSubmitForm()), ["prevent"])),
            onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.onSubmitForm()), ["enter"]))
          }, [
            _createVNode(_component_ion_list, null, {
              default: _withCtx(() => [
                _createVNode(_component_m_datetime, {
                  label: _ctx.$t('Time'),
                  "display-format": "h:mm A",
                  "picker-format": "h:mm A",
                  placeholder: _ctx.$t('Select Time'),
                  name: "time"
                }, null, 8, ["label", "placeholder"]),
                _createVNode(_component_m_textarea, {
                  "auto-grow": true,
                  label: _ctx.$t('Agenda'),
                  name: "agenda"
                }, null, 8, ["label"]),
                _createVNode(_component_m_textarea, {
                  "auto-grow": true,
                  label: _ctx.$t('Notes/Contact'),
                  name: "notes"
                }, null, 8, ["label"]),
                _createVNode(_component_m_select, {
                  label: _ctx.$t('Day'),
                  name: "day"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_select_option, { value: -1 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("Day Prior to Wedding Day")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_select_option, { value: 0 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("Wedding Day")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            })
          ], 32)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}