
import { defineComponent } from '@vue/runtime-core'
import {
 IonItem,
 IonLabel,
} from '@ionic/vue'

export default defineComponent({
  props:{
    label: {type: String, required: true},
    wrapLabel: {type: Boolean, default: false},
    errorMessage: {type: String, default: ""},
  },
  components:{
    IonItem,
    IonLabel,
  },
})
