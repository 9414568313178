
import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent,
  IonButtons,
  IonButton,
  IonBackButton,
  IonIcon,
  IonList,
  IonSelectOption,

  onIonViewDidEnter
 } from '@ionic/vue';

import { checkmark } from 'ionicons/icons';

import { defineComponent, ref } from '@vue/runtime-core';
import { useRouter, useRoute } from 'vue-router';
import { isApiError, ScheduleItem, asDateObject } from '@/lib/api-endpoints';
import MSelect from '@/components/MSelect.vue';
import MDatetime from '@/components/MDatetime.vue';
import MTextarea from '@/components/MTextarea.vue';
import { useStore } from '@/store';
import * as yup from 'yup';
import t from '@/translation';
import { useForm } from 'vee-validate';
import budgetIcons from '@/lib/budget_icons';
import { differenceInCalendarDays, format as dateFormat } from 'date-fns';


export default  defineComponent({
  name: 'WeddingInfo',
  components: { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent,
    IonButtons,
    IonButton,
    IonBackButton,
    IonIcon,
    IonList,
    //IonListHeader,
    //IonGrid,
    //IonRow,
    //IonCol,
    //IonItem,
    //IonLabel,
    //IonDatetime,
    IonSelectOption,
    //IonRadio,
    //IonRadioGroup,

    MTextarea,
    MSelect,
    MDatetime,
  },
  setup(_props){

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const itemSchema = yup.object({
      // eslint-disable-next-line @typescript-eslint/camelcase
      time: yup.string().required().label(t("Time")),
      agenda: yup.string().required().label(t("Agenda")),
      notes: yup.string().label(t("Notes")),
      day: yup.number().required().label(t("Day")),
    });

    // Create a form context with the validation schema
    const { values, validate, resetForm } = useForm({
      validationSchema: itemSchema,
    });
    
    const itemId = ref<number | undefined>(undefined);
    
    function inDays(dateStr: string){
      if (store.state.userData && store.state.userData.wedding){
        return differenceInCalendarDays(asDateObject(dateStr), asDateObject(store.state.userData.wedding.date));
      }
      return 0;
    }

    // Load up the latest user details when entering this page
    onIonViewDidEnter(async () => {
      resetForm();
      if ( route.params.scheduleItemId ){
        itemId.value = parseInt(route.params.scheduleItemId as string);

        if (itemId.value){
        const item = store.state.schedule.find( element => element.id == itemId.value );
          // populate the form with the details of the category to be edited.
          if (item){
            values.time = (asDateObject(item.time)).toISOString();
            values.agenda = item.agenda;
            values.notes = item.notes;
            values.day = inDays(item.time) < 0 ? -1 : 0;
          }
        }
      } else {
        itemId.value = undefined;
      } 
     
    });

    async function onSubmitForm(){
      const result = await validate();
      if (result.valid){

        const inputTime = new Date(values.time!);
        const weddingDate = asDateObject(store.getters.wedding.date);
        const itemTime = new Date(
          weddingDate.getFullYear(),
          weddingDate.getMonth(),
          weddingDate.getDate() + values.day!,
          inputTime.getHours(),
          inputTime.getMinutes(),
        );

        const scheduleItem: ScheduleItem = {
          time: dateFormat(itemTime, "YYYY-MM-DDTHH:mm:ss"),
          agenda: values.agenda!,
          notes: values.notes!,
          // eslint-disable-next-line @typescript-eslint/camelcase
          hide_time: false,
          // eslint-disable-next-line @typescript-eslint/camelcase
          wedding_id: store.getters.wedding.id,
        }

        if (itemId.value){
          scheduleItem.id = itemId.value;
        }

        const result = await store.dispatch('saveScheduleItem', scheduleItem);

        if (! isApiError(result)){
          router.go(-1); // go back to the last page.
          window.setTimeout(resetForm, 2000); // delayed so that validation errors are not seen before the page transistions.
        }
      } 
    }

    return {
      onSubmitForm,
      checkmark,
      budgetIcons,
      route,
      store,
      itemId,

    }
  }
});
