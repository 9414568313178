
import { defineComponent } from '@vue/runtime-core'
import {
 IonTextarea,
} from '@ionic/vue'
import { useField } from 'vee-validate';
import MItem from './MItem.vue';

export default defineComponent({
  props:{
    label: {type: String, required: true},
    name: {type: String, required: true},
  },
  inheritAttrs:false,
  components:{
    MItem,
    IonTextarea,
  },
  methods:{
    emitValue(evt: Event){
      if (evt.target != null){
        const target = evt.target as HTMLInputElement;
        this.$emit('update:modelValue', target.value);
      }
    }
  },
  setup(props) {

    const { errorMessage, value: modelValue, handleChange} = useField (props.name);

   return {
     errorMessage,
     modelValue,
     handleChange,
   };  

  },
})
